import clsx from 'clsx';
import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { batch, useDispatch, useSelector } from 'react-redux';
import { scrollTo } from 'scroll-js';

import { MainActionCreators } from '../state/reducer';
import * as store from '../state/store';

import useFeedEntryOps from './hooks/useFeedEntryOps';
import useNav from './hooks/useNav';
import useStoryOpener from './hooks/useStoryOpener';

import AuthorSection from './AuthorSection';
import DiscussionSection from './DiscussionSection';
import EgoSection from './EgoSection';
import { FeedEntryExploreActions } from './FeedEntryActions';
import FeedEntryHorizontalList from './FeedEntryHorizontalList';
import { AgentLine, StoryDebutMatter, StoryLowerMatter, StoryUnderMatter, StoryUpperMatter } from './FeedEntryMatter';
import useAgentMode from './hooks/useAgentMode';
import useApiClient from './hooks/useApiClient';
import { useSeenRecorderWithDelay } from './hooks/useSeenRecorder';
import useUserMeInternal, { AccountInfoMaybe } from './hooks/useUserMeInternal';
import TwitterIcon from './icon/TwitterIcon';
import YouTubeIcon from './icon/YouTubeIcon';
import { useKeyPress } from './KeyPressContext';
import Button from './lib/Button';
import Modal, { modalGutterClassName } from './lib/Modal';
import Spinner from './lib/Spinner';
import { useShowUnmanagedModal } from './ModalManagerContext';
import RssSection from './RssSection';
import SectionHeaderTab from './SectionHeaderTab';
import ThreadFfa from './ThreadFfa';
import ThreadQnA from './ThreadQnA';
import ThreadTrace from './ThreadTrace';
import TwitterTweet from './TwitterTweet';
import type { VideoSource } from './VideoPlayer';
import YouTubeCustomPlayer from './YouTubeCustomPlayer';

const FeedEntryExploreAgentSection = React.lazy(() => import('./FeedEntryExploreAgentSection'));
const VideoPlayer = React.lazy(() => import('./VideoPlayer'));

type KeyboardSection = 'title' | 'ffa' | 'discussion' | 'qna' | 'trace';
type KeyboardDirection = 'going-up' | 'going-down';

interface IFeedEntryExploreProps {
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  inNewsfeed: boolean | string;

  closeModal: () => void;
}

const FeedEntryExplore = (props: IFeedEntryExploreProps) => {
  const dispatch = useDispatch();
  const apiClient = useApiClient();
  const accountInfo = useUserMeInternal();
  const agentMode = useAgentMode();
  const keyboardControlsActive = useSelector<store.IAppState, boolean>(state => state.keyboardControlsActive);
  const onLoadPathname = useSelector<store.IAppState, string>(state => state.onLoadPathname);

  const { navToFeed } = useNav();
  useSeenRecorderWithDelay([[props.feed, props.entry]], 5000);

  const modalRef = React.useRef<HTMLDivElement | null>(null);

  const md = cfe.ApiHelpers.getEntryMetadata(props.entry);
  const title = cfe.ApiHelpers.getEntryTitleBounded(props.entry);
  const primaryUrl = props.entry.strong_ref?.url ?? props.entry.url;
  const ytVideoId = cfe.ApiHelpers.parseYouTubeVideoIdFromUrl(primaryUrl);
  const tweetId = cfe.ApiHelpers.parseTwitterTweetId(primaryUrl);
  const videoMd = cfe.ApiHelpers.getVideoMetadataExtended(props.entry);

  const entryHasFfaThread = props.entry['.tag'] === 'share';

  const feedEntryOps = useFeedEntryOps(apiClient, accountInfo, props.feed, props.entry, agentMode);
  const { storyOpener } = useStoryOpener();
  const forceOpenStoryExternal = ytVideoId !== null || tweetId !== null;

  const { result: stimGetRes, refresh: stimGetResRefresh } = cfe.ApiHook.useApiReadCache(
    apiClient,
    apiClient.stimulusGet,
    { url: primaryUrl },
    res => res,
    value => dispatch(MainActionCreators.apiCacheSetStimGet(primaryUrl, value)),
    () =>
      useSelector<store.IAppState, cfe.ApiHook.CacheUnit<cfe.ApiData.Data<api.stimulus.IGetResult>>>(
        state => state.apiCache.stimGet.get(primaryUrl) ?? cfe.ApiHook.getCacheEmptySingleton(),
      ),
    props.entry['.tag'] === 'notif',
    {
      onResult: res => {
        batch(() => {
          res.topics.filter(topic => topic.feed).map(topic => dispatch(MainActionCreators.updateFeed(topic.feed!)));
          if (res.debut) {
            dispatch(MainActionCreators.updateFeed(res.debut.feed));
          }
        });
      },
    },
    120,
  );

  const [kbSection, setKbSection] = useState<[KeyboardSection, KeyboardDirection]>(['title', 'going-down']);

  useKeyPress(
    'n',
    () => {
      // n: Select next section
      dispatch(MainActionCreators.setKeyboardControlsActive(true));
      if (kbSection[0] === 'title') {
        if (entryHasFfaThread) {
          setKbSection(['ffa', 'going-down']);
        } else {
          setKbSection(['discussion', 'going-down']);
        }
      }
    },
    kbSection[0] !== 'title',
  );

  useKeyPress(
    'p',
    () => {
      // p: Select prev section
      dispatch(MainActionCreators.setKeyboardControlsActive(true));
      if (kbSection[0] === 'title') {
        setKbSection(['trace', 'going-up']);
      }
    },
    kbSection[0] !== 'title',
  );

  useKeyPress(
    'Enter',
    () => {
      // enter/o: Open link in new tab
      if (kbSection[0] === 'title') {
        storyOpener(props.feed, props.entry, forceOpenStoryExternal);
      }
    },
    kbSection[0] !== 'title',
  );

  useKeyPress(
    'o',
    () => {
      // enter/o: Open link in new tab
      if (kbSection[0] === 'title') {
        storyOpener(props.feed, props.entry, forceOpenStoryExternal);
      }
    },
    kbSection[0] !== 'title',
    10,
  );

  useEffect(() => {
    if (kbSection[0] !== 'title' || !modalRef.current) {
      return;
    }
    scrollTo(modalRef.current, { top: 0, easing: 'ease-in-out', duration: 70 });
  }, [kbSection]);

  const kbNextSection = React.useCallback(() => {
    if (kbSection[0] === 'title') {
      if (entryHasFfaThread) {
        setKbSection(['ffa', 'going-down']);
      } else {
        setKbSection(['discussion', 'going-down']);
      }
    } else if (kbSection[0] === 'ffa') {
      setKbSection(['discussion', 'going-down']);
    } else if (kbSection[0] === 'discussion') {
      setKbSection(['qna', 'going-down']);
    } else if (kbSection[0] === 'qna') {
      setKbSection(['trace', 'going-down']);
    } else if (kbSection[0] === 'trace') {
      setKbSection(['title', 'going-down']);
    }
  }, [kbSection[0], kbSection[1]]);

  const kbPrevSection = React.useCallback(() => {
    if (kbSection[0] === 'title') {
      setKbSection(['trace', 'going-up']);
    } else if (kbSection[0] === 'ffa') {
      setKbSection(['title', 'going-up']);
    } else if (kbSection[0] === 'discussion') {
      if (entryHasFfaThread) {
        setKbSection(['ffa', 'going-up']);
      } else {
        setKbSection(['title', 'going-up']);
      }
    } else if (kbSection[0] === 'qna') {
      setKbSection(['discussion', 'going-up']);
    } else if (kbSection[0] === 'trace') {
      setKbSection(['qna', 'going-up']);
    }
  }, [kbSection[0], kbSection[1]]);

  const showUnmanagedModal = useShowUnmanagedModal();
  const hgcInfo = cfe.ApiHelpers.getHomegrownContentInfo(props.entry);
  const authoredBy = hgcInfo?.author ?? null;
  // Optimizations to resolve thread IDs faster if previews are available.
  const qnaThreadId = props.entry.qna_preview?.thread_id || cfe.ApiData.getData(stimGetRes)?.qna_thread_id;
  const traceThreadId = props.entry.trace_preview?.thread_id || cfe.ApiData.getData(stimGetRes)?.trace_thread_id;

  const updateProgress = React.useCallback(
    (progress: number) => {
      dispatch(MainActionCreators.updateFeedEntryProgress(props.feed.feed_id, props.entry.entry_id, progress));
      if (accountInfo) {
        apiClient.feedEntryMarkProgress({ entry_id: props.entry.entry_id, progress });
      }
    },
    [accountInfo, props.feed, props.entry],
  );

  return (
    <Modal.Container
      ref={modalRef}
      show={showUnmanagedModal}
      close={props.closeModal}
      // Order below other modals managed by the ModalContextManager.
      backdropClassName="!tw-z-[49]"
      closeBtnClassName="!tw-z-[49]"
    >
      {title ? (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      ) : null}
      {videoMd && videoMd[2] ? (
        <React.Suspense fallback={<Spinner lg />}>
          <VideoPlayer
            key={props.entry.entry_id}
            sources={((): VideoSource[] => {
              const sources: VideoSource[] = [];
              // NOTE: video.js uses a built-in http-streaming library to
              // support dash. However, it does not support multi-segment webm.
              // If required, can later switch to videojs-contrib-dash.
              if (videoMd[0].hls) {
                sources.push({
                  src: videoMd[0].hls!,
                  type: 'application/x-mpegURL',
                });
              }
              return sources;
            })()}
            recordStoryOpen={feedEntryOps.recordToHistory}
            startProgress={props.entry.for_viewer.last_visit?.progress}
            updateProgress={updateProgress}
            portrait={videoMd[0].portrait}
            gutterClassName={modalGutterClassName}
            explicitPortraitVideoHeight
            stimUrl={props.entry.url}
          />
        </React.Suspense>
      ) : ytVideoId ? (
        <div className="tw-mb-4">
          <SectionHeaderTab title="YouTube Video" titleSuffix={<YouTubeIcon size="30px" className="tw-ml-2" />} />
          <div
            className="tw-relative tw-flex tw-justify-center tw-bg-black tw-outline tw-outline-black"
            style={
              md['.tag'] === 'ready' && md.content_type['.tag'] === 'video' && !md.content_type.portrait
                ? {
                    left: 'calc((min(max(70vw, 100%), 1024px) - 100%) / -2)',
                    width: 'calc(min(max(70vw, 100%), 1024px))',
                  }
                : undefined
            }
          >
            <YouTubeCustomPlayer
              ytVideoId={ytVideoId}
              recordStoryOpen={feedEntryOps.recordToHistory}
              startProgress={props.entry.for_viewer.last_visit?.progress}
              updateProgress={updateProgress}
              portrait={md['.tag'] === 'ready' && md.content_type['.tag'] === 'video' && !!md.content_type.portrait}
            />
          </div>
        </div>
      ) : null}
      {tweetId ? (
        <React.Suspense fallback={<Spinner lg />}>
          <SectionHeaderTab
            title="Tweet from Twitter"
            titleSuffix={<TwitterIcon size="1.1rem" className="tw-ml-2" />}
            className="tw-mb-4"
          />
          <TwitterTweet tweetId={tweetId} center />
        </React.Suspense>
      ) : null}
      <Modal.Header className="!tw-px-0 !tw-py-0">
        <div className="tw-pt-4 tw-pb-3">
          <div className="tw-pr-4 tw-w-full tw-flex tw-flex-row-reverse tw-text-sm">
            <AgentLine entry={props.entry} agentMode={agentMode} />
          </div>
          <div className={clsx('kb-pad-4', keyboardControlsActive && kbSection[0] === 'title' ? ' kb-sel' : null)}>
            <StoryUpperMatter
              apiClient={apiClient}
              feed={props.feed}
              entry={props.entry}
              goToFeed={navToFeed}
              onOpenStory={() => storyOpener(props.feed, props.entry, forceOpenStoryExternal)}
              compact={false}
              useTitleBadge
              clampTitle={false}
            />
          </div>
          <div className="kb-pad-4-na">
            {cfe.ApiData.hasData(stimGetRes) &&
            stimGetRes.data.debut &&
            stimGetRes.data.debut.entry_id !== props.entry.entry_id ? (
              <StoryDebutMatter navToFeed={navToFeed} entry={props.entry} debut={stimGetRes.data.debut} />
            ) : null}
            <StoryLowerMatter
              apiClient={apiClient}
              feed={props.feed}
              entry={props.entry}
              goToFeed={navToFeed}
              onOpenStory={() => storyOpener(props.feed, props.entry, forceOpenStoryExternal)}
              compact={false}
              showMiniBadgeLine={false}
              showFromLine
              showEditOptions={agentMode}
            />
            <StoryUnderMatter feed={props.feed} entry={props.entry} goToFeed={navToFeed} compact={false} hideBlurb />
          </div>
          {md['.tag'] === 'ready' && md.post && md.post.content.length !== 0 && md.image ? (
            <div className="tw-flex tw-mb-2 tw-w-full">
              <a href={md.image.url} target="_blank" className="tw-mx-auto">
                <img
                  className="tw-max-h-[700px] tw-max-w-full tw-w-auto"
                  src={md.image.url}
                  width={md.image.width}
                  height={md.image.height}
                />
              </a>
            </div>
          ) : null}
          {!tweetId &&
          !ytVideoId &&
          !(md['.tag'] === 'ready' && md.post && md.post.content.length !== 0 && md.image) &&
          onLoadPathname === window.location.pathname ? (
            <div className="tw-mt-3 tw-mb-4">
              <LandingSection entry={props.entry} openStory={feedEntryOps.open} />
            </div>
          ) : null}
          <div className="kb-pad-4-na tw-mt-3">
            <FeedEntryExploreActions entry={props.entry} actions={feedEntryOps} agentMode={agentMode} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        {props.entry['.tag'] === 'share' ? (
          <div>
            <ThreadFfa
              apiClient={apiClient}
              navToFeed={navToFeed}
              accountInfo={accountInfo}
              feed={props.feed}
              entry={props.entry}
              className="tw-my-2 max-lg:tw-pl-2 kb-pad-4 tw-pr-2 tw-text-[0.9rem]"
              kb={{
                kbNextSection,
                kbPrevSection,
                kbSelected: kbSection[0] === 'ffa' ? 'going-down' : null,
              }}
            />
            <div className="tw-mb-4" />
          </div>
        ) : null}
        {props.entry['.tag'] !== 'notif' ? (
          <DiscussionSection
            entry={props.entry}
            kbSelected={kbSection[0] === 'discussion' ? kbSection[1] : null}
            kbNextSection={kbNextSection}
            kbPrevSection={kbPrevSection}
            scrollTarget={modalRef.current ?? undefined}
          />
        ) : null}
        {qnaThreadId ? (
          <div>
            <div className="tw-mt-4" />
            <ThreadQnA
              accountInfo={accountInfo}
              apiClient={apiClient}
              navToFeed={navToFeed}
              size={{
                depth: 4,
                initDepth: 4,
                initRepliesPerLevel: 10,
                repliesPerLevel: 10,
              }}
              threadId={qnaThreadId}
              viaEntry={props.entry}
              authorUserId={authoredBy?.user_id}
              className="kb-pad-4-na"
              kb={{
                kbNextSection,
                kbPrevSection,
                kbSelected: kbSection[0] === 'qna' ? kbSection[1] : null,
                scrollTarget: modalRef.current ?? undefined,
              }}
            />
          </div>
        ) : null}
        {traceThreadId ? (
          <div>
            <div className="tw-mt-4" />
            <ThreadTrace
              accountInfo={accountInfo}
              apiClient={apiClient}
              navToFeed={navToFeed}
              size={{
                depth: 4,
                initDepth: 4,
                initRepliesPerLevel: 10,
                repliesPerLevel: 10,
              }}
              threadId={traceThreadId}
              viaEntryId={props.entry.entry_id}
              authorUserId={authoredBy?.user_id}
              className="kb-pad-4-na"
              kb={{
                kbNextSection,
                kbPrevSection,
                kbSelected: kbSection[0] === 'trace' ? kbSection[1] : null,
                scrollTarget: modalRef.current ?? undefined,
              }}
            />
          </div>
        ) : null}
        {props.entry.feed_ref_counts && props.entry.feed_ref_counts.ego > 0 ? (
          <EgoSection feed={props.feed} entry={props.entry} />
        ) : null}
        {stimGetRes.kind !== 'unknown' ? (
          <div>
            <AuthorSection
              goToFeed={navToFeed}
              apiClient={apiClient}
              authors={cfe.ApiData.apply(stimGetRes, res => res.creators)}
              showAgentUI={agentMode}
              url={props.entry.strong_ref?.url ?? props.entry.url_canonical ?? props.entry.url}
              refreshStimulus={stimGetResRefresh}
            />
          </div>
        ) : null}
        <RssSection entry={props.entry} apiClient={apiClient} goToFeed={navToFeed} showAgentUI={agentMode} />
        {props.entry['.tag'] !== 'notif' ? (
          <div>
            <FeedEntryRelatedStories
              apiClient={apiClient}
              accountInfo={accountInfo}
              goToFeed={navToFeed}
              feed={props.feed}
              entry={props.entry}
              stimGetRes={cfe.ApiData.getData(stimGetRes)}
              showRelatedStoriesToThisFeed={props.inNewsfeed !== false}
              agentMode={agentMode}
            />
          </div>
        ) : null}
        {agentMode ? (
          <React.Suspense fallback={<Spinner lg />}>
            <FeedEntryExploreAgentSection
              apiClient={apiClient}
              accountInfo={accountInfo}
              navToFeed={navToFeed}
              feed={props.feed}
              entry={props.entry}
              stimGetRes={stimGetRes}
              refreshStimulus={stimGetResRefresh}
            />
          </React.Suspense>
        ) : null}
      </Modal.Body>
    </Modal.Container>
  );
};

export default FeedEntryExplore;

const LandingSection = (props: { entry: api.feed.IFeedEntryReference; openStory: () => void }) => {
  const md = cfe.ApiHelpers.getEntryMetadata(props.entry);
  return (
    <>
      {md['.tag'] === 'ready' && md.image ? (
        <img
          className="tw-max-h-[500px] tw-object-cover tw-object-center tw-w-full tw-mb-3"
          src={md.image.url}
          width={md.image.width}
          height={md.image.height}
        />
      ) : null}
      <div className="tw-px-5">
        <div
          className={clsx(
            'tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-3 tw-px-4',
            'tw-rounded tw-border-2 tw-border-dashed tw-border-perpul-light dark:tw-border-perpul-dark',
          )}
        >
          <div className="tw-mb-2 tw-w-full">
            <Button block title="Open story" onClick={props.openStory}>
              Open story
            </Button>
          </div>
          Superego brings together all the important details about this story so you can see the whole picture.
        </div>
      </div>
    </>
  );
};

const FeedEntryRelatedStories = (props: {
  apiClient: api.SuperegoClient;
  accountInfo: AccountInfoMaybe;
  goToFeed: (feed: api.feed.IFeedInfo) => void;
  feed: api.feed.IFeedInfo;
  entry: api.feed.IFeedEntryReference;
  stimGetRes: api.stimulus.IGetResult | null;
  showRelatedStoriesToThisFeed: boolean;
  agentMode: boolean;
}) => {
  const primaryUrl = props.entry.strong_ref?.url ?? props.entry.url;
  return (
    <>
      {props.showRelatedStoriesToThisFeed &&
      (props.feed.type['.tag'] === 'ego' ||
        props.feed.type['.tag'] === 'curated' ||
        props.feed.type['.tag'] === 'rss') ? (
        <div className="tw-mt-4">
          <FeedEntryHorizontalList
            apiClient={props.apiClient}
            accountInfo={props.accountInfo}
            feedId={props.feed.feed_id}
            selected={false}
            smHeader
            compact={false}
            showArchived={false}
            filterVideosOnly={false}
            urlToSkip={primaryUrl}
            titleFullWidth={true}
            headerClass="kb-pad-4-na"
            showFollowButton={true}
            agentMode={props.agentMode}
          />
        </div>
      ) : null}
      {props.stimGetRes
        ? props.stimGetRes.topics
            .filter(topic => topic.feed !== undefined && !topic.no_preview)
            .filter(
              topic =>
                props.showRelatedStoriesToThisFeed ||
                // If opened story is from a hashtag feed, don't show a horiz list
                // for this hashtag.
                props.feed.type['.tag'] !== 'hashtag' ||
                props.feed.url_name !== topic.hashtag,
            )
            .map(topic => {
              return (
                <div key={topic.hashtag} className="tw-mt-4">
                  <FeedEntryHorizontalList
                    apiClient={props.apiClient}
                    accountInfo={props.accountInfo}
                    feedId={topic.feed!.feed_id}
                    selected={false}
                    smHeader
                    compact={false}
                    showArchived={false}
                    filterVideosOnly={false}
                    urlToSkip={primaryUrl}
                    titleFullWidth={true}
                    headerClass="kb-pad-4-na"
                    showFollowButton={true}
                    agentMode={props.agentMode}
                  />
                </div>
              );
            })
        : null}
      {props.entry.via && props.entry.via.feed.type['.tag'] !== 'hashtag' ? (
        <div className="tw-mt-4">
          <FeedEntryHorizontalList
            apiClient={props.apiClient}
            accountInfo={props.accountInfo}
            feedId={props.entry.via.feed.feed_id}
            smHeader
            selected={false}
            compact={false}
            showArchived={false}
            filterVideosOnly={false}
            urlToSkip={primaryUrl}
            titleFullWidth={true}
            headerClass="kb-pad-4-na"
            showFollowButton={true}
            agentMode={props.agentMode}
          />
        </div>
      ) : null}
    </>
  );
};
