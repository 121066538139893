import * as cfe from 'ego-cfe';
import * as api from 'ego-sdk-js';
import { m } from 'framer-motion';
import React, { startTransition, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAuthedApiClient } from './hooks/useApiClient';
import useApiDo from './hooks/useApiDo';
import useNav from './hooks/useNav';
import Modal from './lib/Modal';

import { MainActionCreators } from '../state/reducer';

import EgoMarkdown from './EgoMarkdown';
import CaretDownIcon from './icon/CaretDownIcon';
import { useKeyPress } from './KeyPressContext';
import Alert from './lib/Alert';
import Button from './lib/Button';
import Collapse from './lib/Collapse';
import InputLabel from './lib/InputLabel';
import Spinner from './lib/Spinner';
import TextAreaInput from './lib/TextAreaInput';
import TextInput, { TextInputFooter } from './lib/TextInput';
import PostBoxesEditor, { Box, PostBoxesEditorMethods } from './PostBoxesEditor';

const ThreadCommentModal = (props: { close: () => void; isForeground: boolean; addCommentProps: AddCommentProps }) => {
  const { navToFeed } = useNav();
  const { successCb, ...rest } = props.addCommentProps;
  useKeyPress(
    ['u', 'Escape'],
    () => {
      props.close();
    },
    undefined,
    10,
  );
  return (
    <Modal.Container show={props.isForeground} animate="zoom" close={props.close}>
      <Modal.Header>
        {props.addCommentProps.parentComment ? (
          <>
            <Modal.Heading1>Reply to</Modal.Heading1>
            <div className="tw-text-sm tw-font-bold">{props.addCommentProps.parentComment.author.name}</div>
            <EgoMarkdown variant="comment" content={props.addCommentProps.parentComment.body} goToFeed={navToFeed} />
          </>
        ) : (
          <>
            <Modal.Heading1>{props.addCommentProps.title}</Modal.Heading1>
            {props.addCommentProps.rootCommentOpts?.help}
          </>
        )}
      </Modal.Header>
      <Modal.Body gutter>
        <AddCommentBox
          {...rest}
          successCb={(comment, thread) => {
            successCb(comment, thread);
            props.close();
          }}
        />
      </Modal.Body>
    </Modal.Container>
  );
};

export interface RootCommentOpts {
  ezInput?: 'link';
  maxLength?: number;
  singleLine?: boolean;
  simpleTextOnly?: boolean;
  help?: React.ReactNode;
}

type EnableAiPrompt =
  | { kind: 'yes'; onLoad: boolean }
  | {
      kind: 'no';
    };

interface AddCommentProps {
  title?: string;
  threadId: string;
  parentComment?: api.thread.IComment;
  successCb: (comment: api.thread.IComment, thread: api.thread.IThreadInfo) => void;
  rootCommentOpts?: RootCommentOpts;
  viaEntryId?: string;
  enableAiPrompt?: EnableAiPrompt;
}

const AddCommentBox = (props: AddCommentProps) => {
  const dispatch = useDispatch();
  const apiClient = useAuthedApiClient();
  const { navToFeed } = useNav();

  const [inFlight, setInFlight] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const [commentBody, setCommentBody] = useState('');
  const useCommentBody =
    (props.rootCommentOpts?.ezInput === 'link' || !!props.rootCommentOpts?.simpleTextOnly) && !props.parentComment;
  const commentBodyOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    e.preventDefault();
    if (!props.parentComment && props.rootCommentOpts?.singleLine) {
      setCommentBody(e.currentTarget.value.replace(/\r?\n|\r/, ''));
    } else {
      setCommentBody(e.currentTarget.value);
    }
  };

  const [boxes, setBoxes] = React.useState<Box[]>([]);
  const [topBoxContent, setTopBoxContent] = React.useState('');
  const disableSubmit =
    inFlight ||
    (useCommentBody && commentBody.length === 0) ||
    (!useCommentBody && topBoxContent.length === 0 && boxes.length <= 1);

  const { apiDo: apiThreadCommentAdd, errToast } = useApiDo(apiClient, apiClient.threadCommentAdd);
  const [aiPromptInFlight, setAiPromptInFlight] = useState(false);
  const { apiDo: apiStoryAiPrompt } = useApiDo(apiClient, apiClient.storyAiPrompt);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let body;
    if (useCommentBody) {
      body = commentBody;
    } else {
      if (!postEditorRef.current) {
        return;
      }
      const markdownResult = postEditorRef.current.getMarkdownContent();
      if (markdownResult.kind !== 'ready') {
        return;
      }
      body = markdownResult.content;
    }
    if (body.length === 0) {
      errToast('Say something...');
      return;
    }
    setInFlight(true);
    apiThreadCommentAdd(
      {
        body,
        parent_id: props.parentComment?.comment_id,
        thread_id: props.threadId,
        via: props.viaEntryId,
      },
      {
        onFinally: () => setInFlight(false),
        onResult: res => {
          setCommentBody('');
          props.successCb(res.comment, res.thread);
          if (props.viaEntryId) {
            apiClient.feedEntryGet({ entry_id: props.viaEntryId }).then(resp => {
              if (resp.kind === api.StatusCode.Ok) {
                dispatch(MainActionCreators.updateFeedEntry(resp.result.feed.feed_id, resp.result.entry));
              }
            });
          }
        },
        onRouteErr: (err, defaultErrToast) => {
          if (err['.tag'] === 'bad_body') {
            if (err.bad_body['.tag'] === 'bad_length') {
              errToast('Comment is the wrong length');
            } else if (err.bad_body['.tag'] === 'bad_markdown') {
              errToast('Comment mark up is disallowed');
            }
          } else if (err['.tag'] === 'slow_down') {
            errToast('Slow down', `Try again in ${cfe.Formatter.secondsToHoursMinsSecsStr(err.wait_period)}`);
          } else {
            defaultErrToast();
          }
        },
      },
    );
  };

  const [typewriterAnimation, setTypewriteAnimation] = React.useState(false);
  const askAiClickCount = React.useRef(0);
  const askAiForHelp = () => {
    if (!props.viaEntryId || !props.parentComment) {
      return;
    }
    askAiClickCount.current += 1;
    if (aiPromptInFlight) {
      return;
    }
    setAiPromptInFlight(true);
    apiStoryAiPrompt(
      {
        entry_id: props.viaEntryId,
        prompt: props.parentComment.body,
      },
      {
        onFinally: () => setAiPromptInFlight(false),
        onResult: res => {
          const answeredBy =
            '**AI-Assisted answer' +
            (res.percent_processed !== 100 ? ` based on the first ${res.percent_processed}% of the content:**` : ':**');
          const finalBody = `${answeredBy}\n\n${res.response}`;
          const typewriter = () => {
            setTypewriteAnimation(true);
            let count = 0;
            const intervalId = setInterval(() => {
              if (askAiClickCount.current > 1) {
                count = finalBody.length;
              }
              count += Math.floor(10 * Math.random()) + 1;
              startTransition(() => {
                setCommentBody(finalBody.substring(0, count));
                if (postEditorRef.current) {
                  // WARN: Return value should be checked. Due to a race condition
                  // it's possible for the set to fail (text input not loaded yet).
                  // This generally isn't a problem since a follow up set will
                  // likely succeed, but if the AI answer is short, the typewriter
                  // animation could end before the input loads.
                  postEditorRef.current.setTopTextBoxContent(finalBody.substring(0, count));
                }
              });
              if (count >= finalBody.length) {
                setTypewriteAnimation(false);
                clearInterval(intervalId);
              }
            }, 12);
          };
          typewriter();
        },
        onRouteErr: (err, defaultErrToast) => {
          if (err['.tag'] === 'no_content') {
            errToast('AI does not have access to content');
          } else if (err['.tag'] === 'ai_failed') {
            if (err.ai_failed['.tag'] === 'unavailable') {
              errToast('AI currently unavailable');
            } else if (err.ai_failed['.tag'] === 'no_answer') {
              errToast('AI had trouble answering');
            } else {
              defaultErrToast();
            }
          } else {
            defaultErrToast();
          }
        },
      },
    );
  };

  React.useEffect(() => {
    if (props.enableAiPrompt?.kind === 'yes' && props.enableAiPrompt.onLoad) {
      askAiForHelp();
    }
  }, []);

  const postEditorRef = React.useRef<PostBoxesEditorMethods | null>(null);
  return (
    <form onSubmit={handleSubmit} className="tw-py-4">
      <div className="tw-mb-2">
        {props.enableAiPrompt?.kind === 'yes' &&
        props.viaEntryId &&
        props.parentComment &&
        !props.parentComment.parent_id ? (
          <div className="tw-mb-2 tw-mt-1">
            <Button
              sm
              mimicDisabled={aiPromptInFlight}
              disabled={
                !aiPromptInFlight &&
                !typewriterAnimation &&
                ((useCommentBody && commentBody.length > 0) || (!useCommentBody && topBoxContent.length > 0))
              }
              onClick={askAiForHelp}
            >
              Ask AI for help
            </Button>
            <Spinner sm show={aiPromptInFlight} className="tw-ml-2" />
          </div>
        ) : null}
        {props.enableAiPrompt?.kind === 'yes' && props.enableAiPrompt.onLoad ? (
          <Alert variant="info" className="tw-mb-2">
            Please review the answer by the AI before posting it.
          </Alert>
        ) : null}
        {props.rootCommentOpts?.ezInput === 'link' && !props.parentComment ? (
          <AddLinkForm updateCommentBody={setCommentBody} />
        ) : props.rootCommentOpts?.simpleTextOnly && !props.parentComment ? (
          <>
            <TextAreaInput
              autoHeight={300}
              onChange={commentBodyOnChange}
              value={commentBody}
              maxLength={
                !props.parentComment && props.rootCommentOpts?.maxLength ? props.rootCommentOpts.maxLength : 1000
              }
            />
            <TextInputFooter
              value={commentBody}
              maxLength={
                !props.parentComment && props.rootCommentOpts?.maxLength ? props.rootCommentOpts.maxLength : 1000
              }
              hideMaxLengthUntilHalfway
            />
          </>
        ) : (
          <PostBoxesEditor
            ref={postEditorRef}
            initMarkdownContent=""
            maxBoxes={2}
            noBoxesWithMediaUpload
            topTextBoxContentChanged={setTopBoxContent}
            setBoxesOnMajorChange={setBoxes}
          />
        )}
      </div>
      {useCommentBody &&
      (props.parentComment || (!props.rootCommentOpts?.ezInput && !props.rootCommentOpts?.simpleTextOnly)) ? (
        // FIXME: ShowPreview is more-or-less disabled at this point.
        // Consider bringing it back, but to do so, will have to solve how to
        // generate a live preview for boxes; currently, the preview is only a
        // snapshot.
        <div className="tw-mb-4">
          <div
            role="button"
            className="tw-text-link-blue tw-select-none"
            onClick={() => {
              setShowPreview(!showPreview);
              if (!useCommentBody && postEditorRef.current) {
                const editorResult = postEditorRef.current.getMarkdownContent();
                if (editorResult.kind === 'not-ready') {
                  throw Error('Unexpected media not-ready');
                } else if (editorResult.kind === 'need-fix') {
                  errToast('Please fix error');
                  return;
                }
                setCommentBody(editorResult.content);
              }
            }}
          >
            <span>
              <m.span className="tw-inline-block tw-mr-1" animate={{ rotateZ: showPreview ? '180deg' : '0deg' }}>
                <CaretDownIcon size="1rem" />
              </m.span>
              {showPreview ? 'Hide Preview' : 'Show Preview'}
            </span>
          </div>
          <Collapse open={showPreview}>
            <div className="tw-mt-4 tw-mb-2 tw-bg-striped-light dark:tw-bg-striped-dark tw-p-2">
              <EgoMarkdown variant="comment" content={commentBody} goToFeed={navToFeed} />
            </div>
          </Collapse>
        </div>
      ) : null}
      <Button type="submit" disabled={disableSubmit}>
        Post
      </Button>
    </form>
  );
};

const AddLinkForm = (props: { updateCommentBody: (body: string) => void }) => {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    let body;
    if (title.length > 0 || url.length > 0) {
      body = `[${title}](${cfe.ApiHelpers.addUrlScheme(url)})`;
    } else {
      body = '';
    }
    props.updateCommentBody(body);
  }, [title, url]);

  return (
    <>
      <InputLabel>Source Title</InputLabel>
      <TextInput
        type="text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          e.preventDefault();
          setTitle(e.currentTarget.value);
        }}
        value={title}
        maxLength={1000}
        required
      />
      <div className="tw-mt-4"></div>
      <InputLabel>Source URL</InputLabel>
      <TextInput
        type="text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          e.preventDefault();
          setUrl(e.currentTarget.value);
        }}
        value={url}
        maxLength={2000}
        required
      />
    </>
  );
};

export default ThreadCommentModal;
