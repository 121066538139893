import clsx from 'clsx';
import React from 'react';

import { SvgIconProps } from './SvgIcon';

/**
 * Effort taken to abide by YouTube branding guidelines:
 * https://www.youtube.com/howyoutubeworks/resources/brand-resources/
 *
 * 1. If icon colored red, the inner triangle must be white.
 * 2. If icon is monochrome color, the inner triangle must be transparent.
 * 3. The only monochrome colors allowed are almost-black (#121212) and white.
 *
 * While not enforced in this component, the brand guidelines require the
 * icon to have a minimum height of 20px, which maps to size 1.9rem or 30px.
 */
const YouTubeIcon = (props: SvgIconProps & { colorOverride?: 'almost-black' | 'white' }) => (
  <svg
    viewBox="0 0 576 512"
    height={props.size}
    width={props.size}
    className={clsx(
      'tw-relative',
      props.offsetUp ? '-tw-top-[0.08em]' : null,
      props.colorOverride === 'almost-black'
        ? 'tw-text-[#121212]'
        : props.colorOverride === 'white'
          ? 'tw-text-white'
          : 'tw-text-[#c4302b]',
      props.className,
    )}
  >
    <path
      fill="currentColor"
      d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
    />
    {!props.colorOverride ? (
      // This was manually added to abide by the YouTube branding guidelines
      <path fill="#ffffff" d="M232.145 337.591V175.185l142.739 81.205-142.739 81.205z" />
    ) : null}
  </svg>
);

export default YouTubeIcon;
